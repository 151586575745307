import React, { PropsWithChildren } from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';

const growthFeatures = [
  `Logovermelding op de beamer tijdens het weekend van de zitting`,
  `Logovermelding op de website`,
  `Gratis entreekaart galazitting CV de Böschule op 3-1-2025`,
  `Gratis entreekaart zitting JCV de Böschuulkes op 4-1-2025`,
];

type Props = {
  activeIndex: number;
  onPressPackage: (index: number) => void;
};

export default function SponsorPaketten({
  children,
  activeIndex,
  onPressPackage,
}: PropsWithChildren<Props>) {
  const handlePackageClick = (index: number) => {
    onPressPackage?.(index);
  };

  return (
    <div className="mt-16 pb-12 lg:mt-20 lg:pb-10">
      <div className="relative z-0">
        <div className="absolute inset-0 h-5/6 lg:h-2/3" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative lg:grid lg:grid-cols-7">
            {/* Sponsorpakket standaard */}
            <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-1 lg:col-end-4 lg:row-start-1 lg:row-end-4">
              <div
                className="relative rounded-lg shadow-xl h-full"
                onClick={() => handlePackageClick(0)}
                onKeyDown={(event) =>
                  event.key === `Enter` && handlePackageClick(0)
                }
                tabIndex={0}
                role="button"
              >
                {activeIndex === 0 && (
                  <div className="w-10 h-10 -mt-4 -ml-2 absolute z-50">
                    <CheckCircleIcon
                      color="green"
                      className="bg-white rounded-full"
                    />
                  </div>
                )}
                <div
                  className={`pointer-events-none absolute z-10 inset-0 rounded-lg border-2 ${
                    activeIndex === 0 ? `border-primary` : ``
                  } `}
                  aria-hidden="true"
                />
                <div className="bg-white rounded-t-lg px-6 pt-12 pb-10 rounded-lg border-2">
                  <div>
                    <h3
                      className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6"
                      id="tier-growth"
                    >
                      Standaard
                    </h3>
                    <div className="mt-4 flex items-center justify-center">
                      <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                        <span className="mt-2 mr-2 text-4xl font-medium">
                          €
                        </span>
                        <span className="font-extrabold">75,-</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                  <ul className="space-y-4">
                    {growthFeatures.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckIcon
                            className="flex-shrink-0 h-6 w-6 text-green-500"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-3 text-base font-medium text-gray-500">
                          {feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* Sponsorpakket eigen bedrag */}
            <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-5 lg:col-end-8 lg:row-start-1 lg:row-end-4">
              <div
                className="relative rounded-lg shadow-xl h-full"
                onClick={() => handlePackageClick(1)}
                onKeyDown={(event) =>
                  event.key === `Enter` && handlePackageClick(1)
                }
                tabIndex={0}
                role="button"
              >
                {activeIndex === 1 && (
                  <div className="w-10 h-10 -mt-4 -ml-2 absolute z-50">
                    <CheckCircleIcon
                      color="green"
                      className="bg-white rounded-full"
                    />
                  </div>
                )}
                <div
                  className={`pointer-events-none absolute z-10 inset-0 rounded-lg border-2 ${
                    activeIndex === 1 ? `border-primary` : ``
                  } `}
                  aria-hidden="true"
                />
                <div className="bg-white rounded-t-lg px-6 pt-12 pb-10 rounded-lg border-2">
                  <div>
                    <h3
                      className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6"
                      id="tier-growth"
                    >
                      Eigen bedrag
                    </h3>
                    {children}
                  </div>
                </div>
                <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                  <ul className="space-y-4">
                    {growthFeatures.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckIcon
                            className="flex-shrink-0 h-6 w-6 text-green-500"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-3 text-base font-medium text-gray-500">
                          {feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {/* {sponsorPaketten.map((pakket, index) => {
              const colStart = index === 0 ? 1 : 5;
              const colEnd = index === 0 ? 4 : 8;

              const selected = activeIndex === index;
              return (
                // Sponsorpakket standaard
                <div
                  className={`mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-1 lg:col-end-4 lg:row-start-1 lg:row-end-4`}
                >
                  <div
                    className="relative rounded-lg shadow-xl h-full"
                    onClick={() => handlePackageClick(index)}
                    onKeyDown={(event) =>
                      event.key === `Enter` && handlePackageClick(index)
                    }
                    tabIndex={0}
                    role="button"
                  >
                    {selected && (
                      <div className="w-10 h-10 -mt-4 -ml-2 absolute z-50">
                        <CheckCircleIcon
                          color="green"
                          className="bg-white rounded-full"
                        />
                      </div>
                    )}
                    <div
                      className={`pointer-events-none absolute z-10 inset-0 rounded-lg border-2 ${
                        selected ? `border-primary` : ``
                      } `}
                      aria-hidden="true"
                    />
                    <div className="bg-white rounded-t-lg px-6 pt-12 pb-10 rounded-lg border-2">
                      <div>
                        <h3
                          className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6"
                          id="tier-growth"
                        >
                          {pakket.name}
                        </h3>
                        {pakket.showPriceInput ? (
                          children
                        ) : (
                          <div className="mt-4 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                €
                              </span>
                              <span className="font-extrabold">75,-</span>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                      <ul className="space-y-4">
                        {growthFeatures.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <CheckIcon
                                className="flex-shrink-0 h-6 w-6 text-green-500"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })} */}
            {/* <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-5 lg:col-end-8 lg:row-start-1 lg:row-end-4">
              <div className="relative z-10 rounded-lg shadow-xl h-full">
                <div
                  className="pointer-events-none absolute inset-0 rounded-lg border-2 border-primary"
                  aria-hidden="true"
                />
                <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                  {children}
                </div>
                <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                  <ul className="space-y-4">
                    {growthFeatures.map((feature) => (
                      <li key={feature} className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckIcon
                            className="flex-shrink-0 h-6 w-6 text-green-500"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-3 text-base font-medium text-gray-500">
                          {feature}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
